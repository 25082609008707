import { useState } from 'react';
import apiClient, { handleError } from './ApiClient';
import { StorageKeys } from "../constants/StorageKeys";
import { storage } from "../utils/Storage";

const GET_APPOINTMENTS_ENDPOINT = '/v1/booking/getAppointments';

const useGetAppointments = () => {
  const supply = storage.get(StorageKeys.SELECTED_SUPPLY);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  
  const getAppointments = async (requestData) => {
    console.log("getAppointments api called", GET_APPOINTMENTS_ENDPOINT, requestData);
    setLoading(true);
    setError(null);

    const url = composeUrl(requestData, supply);

    try {
      const response = await apiClient.get(url);
      setData((oldData) => [...oldData, ...response.data]);
    } catch (error) {
      handleError(error, setError);
    } finally {
      setLoading(false);
    }
  };

  return { getAppointments, loading, error, data };
};

const composeUrl = (requestData, supply) => {
  let url = GET_APPOINTMENTS_ENDPOINT + "/" + supply?.id;

  if (requestData?.date) {
    const date = new Date(requestData.date);
    const formattedDate = date.toISOString().split('T')[0];
    url += "?date=" + formattedDate;
  }

  return url;
}

export default useGetAppointments;
