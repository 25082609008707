const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

if (!API_BASE_URL) {
  console.error('REACT_APP_API_BASE_URL is not set.');
}

if (API_BASE_URL === "http://localhost:8080/api") {
  console.warn('REACT_APP_API_BASE_URL is http://localhost:8080/api. Make sure the application is running in local environment.');
}

export default API_BASE_URL;