export const Routes = {
  SUPPLY: "/supply",
  BOOKING: "/booking",
  PRE_RESERVE: "/pre-reserve",
  CONFIRM_BOOKING: "/confirm-booking",
  LOGIN: "/login",
  PROFILE: "/profile",
  LOGOUT: "/logout",
  COMPLETE_REGISTRATION: "/complete-registration",
  SUCCESSFUL_AUTH: "/successful-auth",
  ERROR: "/error",
  OPENING_HOURS: "/#openingHours",
  CONTACTS: "/#contacts"
};