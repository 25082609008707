// usePagination.js
import { useState, useMemo, useCallback } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const usePagination = (items = [], fetchNextPages) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  const pageSize = useMemo(() => {
    const pageSizeMap = {
      xs: 3,
      sm: 4,
      md: 4,
      lg: 6,
      xl: 6,
    };

    if (isXs) return pageSizeMap.xs;
    if (isSm) return pageSizeMap.sm;
    if (isMd) return pageSizeMap.md;
    if (isLg) return pageSizeMap.lg;
    if (isXl) return pageSizeMap.xl;

    return pageSizeMap.xs; // Default size for safety
  }, [isXs, isSm, isMd, isLg, isXl]);

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(() => Math.ceil(items.length / pageSize), [items.length, pageSize]);

  const paginatedItems = useMemo(() => {
    const startIdx = (currentPage - 1) * pageSize;
    const endIdx = Math.min(startIdx + pageSize, items.length);
    return items.slice(startIdx, endIdx);
  }, [items, currentPage, pageSize]);

  const handlePrevPage = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [currentPage]);

  const handleNextPage = useCallback(() => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      if (currentPage >= totalPages - 1 && fetchNextPages) {
        fetchNextPages();
      }
    }
  }, [currentPage, totalPages, fetchNextPages]);

  return {
    pageSize,
    currentPage,
    totalPages,
    paginatedItems,
    handlePrevPage,
    handleNextPage,
  };
};

export default usePagination;
