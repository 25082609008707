import React, { useState } from 'react';
import { NavLink, Route } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import MenuItem from './MenuItem';
import { Routes } from '../constants/Routes';
import {
  AppBar,
  Button,
  Stack,
  Container,
  Box,
  useMediaQuery,
  useTheme,
  Divider,
  IconButton,
  Drawer,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export default function Header() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = (event) => {
    if (event && (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))) {
      return;
    }
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
  };

  const drawerContent = (
    <Stack
      sx={{ width: 250 }}
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <Stack divider={<Divider orientation="horizontal" flexItem />}>
        <MenuItem index primary="Főoldal" />
        <MenuItem to={Routes.BOOKING} primary="Foglalás" />
        <MenuItem to={Routes.SUPPLY} primary="Szolgáltatások" />
        <MenuItem to={Routes.OPENING_HOURS} primary="Nyitvatartás" componentType={HashLink} smooth />
        <MenuItem to={Routes.CONTACTS} primary="Kapcsolatok" componentType={HashLink} smooth />
        <MenuItem to={Routes.PROFILE} primary="Profil" />
        <MenuItem to={Routes.LOGOUT} primary="Kijelentkezés" />
      </Stack>
    </Stack>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Container maxWidth="xl" disableGutters={downMd}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box bgcolor={"white"}>
            <NavLink to="/" style={{ textDecoration: 'none', color: 'white' }}>
              <img
                src="/logo.png"
                alt="Company Logo"
                style={{ height: '70px' }}
              />
            </NavLink>
          </Box>

          {downMd ? (
            <IconButton color="inherit" onClick={handleDrawerToggle}>
              <MenuIcon fontSize="large" />
            </IconButton>
          ) : (
            <Stack
              direction="row"
              spacing={1}
              alignItems={"center"}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <NavLink index="true" style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Főoldal</Button>
              </NavLink>
              <NavLink to={Routes.BOOKING} style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Foglalás</Button>
              </NavLink>
              <NavLink to={Routes.SUPPLY} style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Szolgáltatások</Button>
              </NavLink>
              <HashLink smooth to={Routes.OPENING_HOURS} style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Nyitvatartás</Button>
              </HashLink>
              <HashLink smooth to={Routes.CONTACTS} style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Kapcsolatok</Button>
              </HashLink>
              <NavLink to={Routes.PROFILE} style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Profil</Button>
              </NavLink>
              <NavLink to={Routes.LOGOUT} style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Kijelentkezés</Button>
              </NavLink>
            </Stack>
          )}
        </Stack>
      </Container>

      <Drawer anchor={"right"} open={drawerOpen} onClose={handleDrawerToggle}>
        {drawerContent}
      </Drawer>
    </AppBar>
  );
}
