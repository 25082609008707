import { useState } from 'react';
import apiClient, { handleError } from './ApiClient';

const GET_SUPPLIES_ENDPOINT = '/v1/supply/getSupplies';

const useGetSupplies = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  
  const getSupplies = async () => {
    console.log("getSupplies api called", GET_SUPPLIES_ENDPOINT);
    setLoading(true);
    setError(null);

    try {
      const response = await apiClient.get(GET_SUPPLIES_ENDPOINT);
      setData(response.data.supplies);
    } catch (error) {
      handleError(error, setError);
    } finally {
      setLoading(false);
    }
  };

  return { getSupplies, loading, error, data };
};

export default useGetSupplies;
