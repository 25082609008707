import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const SelectedSupply = ({ supply }) => {
  const theme = useTheme(); 

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: "5px",
        border: `1px solid ${theme.palette.primary.main}`,
        minWidth: "150px",
      }}
    >
      <Typography color={theme.palette.primary.main} fontWeight="bold" gutterBottom>
        {supply?.name}
      </Typography>
      <Typography variant="body1">
        {supply?.price} Ft
      </Typography>
      <Typography variant="body1">
        {supply?.duration} perc
      </Typography>
    </Box>
  );
};

export default SelectedSupply;