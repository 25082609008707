import React from 'react';
import { Button, Typography } from '@mui/material';

const CustomButton = ({ variant = 'contained', color = 'primary', onClick, text, textTransform = 'capitalize' }) => {
  return (
    <Button variant={variant} color={color} onClick={onClick}>
      <Typography fontWeight="bold" textTransform={textTransform}>
        {text}
      </Typography>
    </Button>
  );
};

export default CustomButton;
