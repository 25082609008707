import axios from 'axios';
import API_BASE_URL from '../config';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

export default apiClient;

export const handleError = (error, setError) => {
  if (error.code === "ERR_NETWORK") {
    console.error("Network error");
    setError({ message: "Sajnos a szerver jelenleg nem elérhető. Foglalj időpontot telefonon keresztül. 06 30 452 8948" })
  } else if (error?.response?.data?.message) {
    console.error("Response error", error.response);
    setError({ message: error.response.data.message })
  } else {
    console.error("Response error", error)
    setError({ message: "Nem sikerült a foglalás, próbáld meg később vagy foglalj időpontot telefonon keresztül.  06 30 452 8948 (Error code: " + error.code + ")" })
  }
};
