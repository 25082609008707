import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#95570b",
      light: "#C9A983",
    },
    secondary: {
      main: "#000000",
      light: "#323232"
    },
  },
});

export default theme;
