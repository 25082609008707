import { 
  Typography,
  Button,
  Paper 
} from "@mui/material";
import Page from "../components/Page";
import API_BASE_URL from '../config';
import isEmbeddedBrowser from "../utils/embeddedBrowserChecker";
import React, { useState, useEffect } from 'react';

const LoginLayout = () => {
  const [embeddedBrowser, setEmbeddedBrowser] = useState(false);

  useEffect(() => {
    setEmbeddedBrowser(isEmbeddedBrowser());
  }, []);

  const handleSocialLogin = (provider) => {  
    window.location = API_BASE_URL + "/v1/auth/initiateSocialLogin?provider=" + provider; 
  };

  return (
    <Page>
      <Paper elevation={3} sx={{py: 5, px: 4, display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="h4" fontWeight="bold" color="primary" gutterBottom>Bejelentkezés</Typography>

        <Typography variant="body1" color="text.primary" mb={5} noWrap>
          A foglalás véglegesítéséhez be kell jelentkezned
        </Typography>

        {!embeddedBrowser &&
          <Button
            variant="outlined"
            color="primary"
            startIcon={<img src="/google-icon.svg" alt="Google Icon" style={{ width: '18px', height: '18px' }} />} 
            onClick={() => handleSocialLogin("google")}
          >
            <Typography textTransform="none" color="secondary" noWrap>Bejelentkezés Google-lal</Typography>
          </Button> 
        }
        
        {embeddedBrowser && (
          <>
            <Typography variant="h6" color="error" mt={2} textAlign="left" fontWeight="bold">
              Használd az alkalmazást egy másik böngészőből!
            </Typography>
            <Typography variant="body2" color="error" mt={2} textAlign="left" fontWeight="bold">
              Ha messenger, instagram vagy egyéb másik alkalmazásból nyitod meg a weboldalt akkor a bejelentkezés nem lehetséges. Másold át a linket egy böngészőbe!
            </Typography>
          </>
        )}

        {/*<Button
          disabled
          variant="outlined"
          color="primary"
          startIcon={<img src="/google-icon.svg" alt="Google Icon" style={{ width: '18px', height: '18px' }} />} 
          onClick={() => handleSocialLogin("facebook")}
        >
            <Typography textTransform="none" color="secondary" noWrap>Bejelentkezés Facebook-lal</Typography>
        </Button>*/}
      </Paper>
    </Page>  
  );
};

export default LoginLayout;
