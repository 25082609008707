import { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  Button,
  Paper,
  useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StorageKeys } from "../constants/StorageKeys";
import { storage } from "../utils/Storage";
import CustomSnackbar from "../components/CustomSnackbar";
import Page from '../components/Page';
import useConfirmBooking from "../api/useConfirmBooking";

const ConfirmBookingLayout = () => {
  const { confirmBooking, error } = useConfirmBooking();
  const selectedSupply = storage.get(StorageKeys.SELECTED_SUPPLY);
  const selectedAppointment = storage.get(StorageKeys.SELECTED_APPOINTMENT);
  const navigate = useNavigate();
  const theme = useTheme();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  
  useEffect(() => {
    if (error) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  }, [error]);

  const handleBookingConfirmation = async () => {
    const preReservedBookingId =  storage.get(StorageKeys.PRE_RESERVED_BOOKING_ID);
    
    const response = await confirmBooking(preReservedBookingId);
    if (!response) {
      return;
    }

    storage.remove(StorageKeys.SELECTED_SUPPLY);
    storage.remove(StorageKeys.SELECTED_APPOINTMENT);
    storage.remove(StorageKeys.PRE_RESERVED_BOOKING_ID);
    navigate('/profile');
  };

  return (
    <>
      <CustomSnackbar open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarMessage}/>
      
      <Page>
        <Paper elevation={3} sx={{ py: 5, px: 4, width: '100%' }}>
          <Typography variant="h4" textAlign="center" fontWeight="bold" mb={4}>Foglalás véglegesítése</Typography>
          <Stack spacing={3}>
            <Box>
              <Typography>Szolgáltatás</Typography>
              <Box sx={{ 
                p: 2, 
                borderRadius: "5px", 
                border: `1px solid ${theme.palette.primary.main}`,
                minWidth: "150px" 
              }}>
                <Typography color={theme.palette.primary.main} fontWeight="bold" gutterBottom>{selectedSupply.name}</Typography>
                <Typography variant="body1">{selectedSupply.price} Ft</Typography>
                <Typography variant="body1">{selectedSupply.duration} perc</Typography>
              </Box>
            </Box>
            
            <Box>
              <Typography>Időpont</Typography>
              <Box sx={{ 
                p: 2, 
                borderRadius: "5px", 
                border: `1px solid ${theme.palette.primary.main}`,
                minWidth: "150px" 
              }}>
                <Typography fontWeight="bold">{selectedAppointment.date.replaceAll("-", ".")}</Typography>
                <Typography color="text.primary">{selectedAppointment.time}</Typography>
              </Box>
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={handleBookingConfirmation}
            >
              Foglalás
            </Button>
          </Stack>
        </Paper>
      </Page>
    </>
  );
};

export default ConfirmBookingLayout;
