import { useState } from 'react';
import apiClient, { handleError } from './ApiClient';
import { StorageKeys } from "../constants/StorageKeys";
import { storage } from "../utils/Storage";
import { useNavigate } from "react-router-dom";

const getUserEndpoint = '/v1/user/getUser';

const useGetUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  
  const getUser = async () => {
    console.log("getUser api called", getUserEndpoint);
    setLoading(true);
    setError(null);

    try {
      const response = await apiClient.get(getUserEndpoint);
      setData(response.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        //TODO make it common
       storage.set(StorageKeys.IS_USER_AUTHENTICATED, false)
       console.log("Request is unauthenticated, redirect needed to login route");
       storage.set(StorageKeys.TARGET_ROUTE_BEFORE_LOGIN, "/profile");
       navigate("/login");
      } else {
        handleError(error, setError);
      }
    } finally {
      setLoading(false);
    }
  };

  return { getUser, loading, error, data };
};

export default useGetUser;
