import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  Stack,
  CircularProgress,
  Alert
} from '@mui/material';
import useCompleteRegistration from '../../api/useCompleteRegistration';
import Page from '../../components/Page';
import CustomSnackbar from '../../components/CustomSnackbar';

const validationSchema = yup.object({
  name: yup.string().required("A név megadása kötelező!"),
  phoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, 'A telefonszám helytelen!')
    .required("A telefonszám megadása kötelező!"),
});

const CompleteRegistrationLayout = () => {
  const { completeRegistration, loading, error, data } = useCompleteRegistration();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (error) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  }, [error]);

  const formik = useFormik({
    initialValues: {
      name: '',
      phoneNumber: '',
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      completeRegistration(values); 
    },
  });

  return (
    <>
      <CustomSnackbar 
        open={snackbarOpen} 
        setOpen={setSnackbarOpen}
        message={snackbarMessage}/>
      
      <Page>
        <Paper elevation={3} sx={{ py: 5, px: 4, width: '100%' }}>
          <Typography variant="h6" textAlign="center" fontWeight="bold" mb={4}>Szükségünk van még néhány adatra</Typography>

          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Név"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                label="Telefonszám"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
              />
              <Box display="flex" justifyContent="center">
                <Button
                  width="30%"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Tovább"}
                </Button>
              </Box>
            </Stack>
          </form>

          {data && (
            <Alert severity="success" sx={{ mt: 2 }}>
              Sikeres regisztráció!
            </Alert>
          )}
        </Paper>
      </Page>
    </>
  );
};

export default CompleteRegistrationLayout;
