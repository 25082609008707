import { 
  Typography,
  Paper 
} from "@mui/material";
import Page from "../components/Page";
import { StorageKeys } from "../constants/StorageKeys";
import { storage } from "../utils/Storage";
import usePreReserve from '../api/usePreReserve';
import { Routes } from "../constants/Routes"
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import ErrorSnackbar from '../components/CustomSnackbar';
import Loading from '../components/Loading';

const PreReserveLayout = () => {
  const { preReserve, loading, error} = usePreReserve();
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (error)  {
      setSnackbarMessage(error.message)
      setSnackbarOpen(true);
    }
  }, [error]);

  useEffect(() => {
    preReservAppointment();
  }, [])

  const preReservAppointment = async () => {
    const selectedSupply = storage.get(StorageKeys.SELECTED_SUPPLY);
    const selectedAppointment = storage.get(StorageKeys.SELECTED_APPOINTMENT);
    if (!selectedSupply || !selectedAppointment) {
      console.error("Selected supply or appointment is missing");
      return;
    }
  
    const preReserveRequest = {
      supplyId: selectedSupply.id,
      date: selectedAppointment.date,
      startTime: selectedAppointment.time,
    };
  
    try {
      const preReservedBookingId = await preReserve(preReserveRequest);
      if (!preReservedBookingId) {
        console.error("Error during processing pre-reserve");
        return;
      }
  
      storage.set(StorageKeys.PRE_RESERVED_BOOKING_ID, preReservedBookingId);
      storage.set(StorageKeys.TARGET_ROUTE_BEFORE_LOGIN, Routes.CONFIRM_BOOKING);
      navigate(Routes.CONFIRM_BOOKING);
    } catch (error) {
      console.error("Error in pre-reserving the appointment:", error);
    }
  };

  return (
    <>
      <ErrorSnackbar 
        open={snackbarOpen} 
        setOpen={setSnackbarOpen}
        message={snackbarMessage}/>
      
      <Page>
        <Paper elevation={3} sx={{py: 5, px: 4, display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant="h4" fontWeight="bold" color="primary" gutterBottom>Előfoglalás</Typography>

          { loading && <Loading />}
        </Paper>
    </Page>
    </>
      
  );
};

export default PreReserveLayout;
