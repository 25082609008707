import { Typography, Paper, } from "@mui/material";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { StorageKeys } from "../../constants/StorageKeys";
import { storage } from "../../utils/Storage";
import Page from "../../components/Page";
import { Routes } from "../../constants/Routes";

const SuccessfulLoginLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    storage.set(StorageKeys.IS_USER_AUTHENTICATED, true);
  
    const targetRouteBeforLogin = storage.get(StorageKeys.TARGET_ROUTE_BEFORE_LOGIN);
    storage.remove(StorageKeys.TARGET_ROUTE_BEFORE_LOGIN);
  
    if (targetRouteBeforLogin) {
      navigate(targetRouteBeforLogin);
    } else {
      navigate(Routes.PROFILE);
    }
  }, []);

  return (
    <Page>
      <Paper elevation={3} sx={{ py: 5, px: 4, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Sikeres belépés
        </Typography>
      </Paper>
    </Page>
  );
};

export default SuccessfulLoginLayout;
