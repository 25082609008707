import { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import SupplyCard from "../components/SupplyCard";
import useGetSupplies from "../api/useGetSupplies";
import Page from "../components/Page";
import CustomSnackbar from "../components/CustomSnackbar";
import Loading from "../components/Loading";

const SupplyLayout = () => {
  const { getSupplies, loading, error, data } = useGetSupplies();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
 
  useEffect(() => {
    getSupplies();
  }, []);

  useEffect(() => {
    if (error) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  }, [error]);

  return (
    <>
      <CustomSnackbar 
        open={snackbarOpen} 
        setOpen={setSnackbarOpen}
        message={snackbarMessage}/>
        
      <Page>
        {loading && <Loading />}

        {!loading && data && (
          <Stack spacing={3} width="100%">
            {data.map((supply) => (
              <SupplyCard
                key={supply.id}
                supply={supply}
              />
            ))}
          </Stack>
        )}
      </Page> 
    </>
  );
};

export default SupplyLayout;
