import { Box, Typography, Paper} from "@mui/material";
import Page from "../../components/Page";

const ErrorLayout = () => {
  return (
    <Page>
      <Paper elevation={3} sx={{ py: 5, px: 4, width: '100%' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" fontWeight="bold" color="red">Sajnáljuk hiba történt!</Typography>
          <Typography variant="body2" color="red">Próbáld újra később</Typography>
        </Box>
      </Paper>
    </Page>
  )
}

export default ErrorLayout;
