import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const MenuItem = ({ to, primary, componentType = NavLink, smooth = false, onClick }) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    if (onClick) {
      event.preventDefault();
      onClick();
    }

    if (componentType === NavLink || componentType === HashLink) {
      navigate(to, { replace: true });
    }
  };

  return (
    <ListItem
      sx={{
        textDecoration: 'none',
        color: 'inherit',
        '&:hover': {
          backgroundColor: theme => theme.palette.action.hover,
          color: theme => theme.palette.primary.main
        }
      }}
      onClick={handleClick}
    >
      <ListItemText primary={primary} />
    </ListItem>
  );
};

export default MenuItem;