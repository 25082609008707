import { useState } from 'react';
import apiClient, { handleError } from './ApiClient';
import { StorageKeys } from "../constants/StorageKeys";
import { storage } from "../utils/Storage";
import { useNavigate } from "react-router-dom";

const confirmBookingEndpoint = '/v1/booking/confirm';

const useConfirmBooking = () => {
  const preReservedBookingId = storage.get(StorageKeys.PRE_RESERVED_BOOKING_ID);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const confirmBooking = async (requestData) => {
    console.log("confirmBooking api called", confirmBookingEndpoint, requestData);
    setLoading(true);
    setError(null);

    const url = confirmBookingEndpoint + "/" + preReservedBookingId;
    try {
      const response = await apiClient.post(url);
      setData(response.data);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        //TODO make it common
        storage.set(StorageKeys.IS_USER_AUTHENTICATED, false)
        console.log("Request is unauthenticated, redirect needed to login route");
        storage.set(StorageKeys.TARGET_ROUTE_BEFORE_LOGIN, "/confirm-booking");
        navigate("/login");
      } else {
        handleError(error, setError);
      }      
    } finally {
      setLoading(false);
    }
  };

  return { confirmBooking, loading, error, data };
};

export default useConfirmBooking;
