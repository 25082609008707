import React from 'react'
import { CircularProgress } from '@mui/material'

const Loading = () => {
  return (
    <CircularProgress size={72} sx={{ my: 5 }} />
  )
}

export default Loading
