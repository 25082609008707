import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Grid,
  Card,
  Paper,
  IconButton,
  Drawer,
  useTheme
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { StorageKeys } from "../constants/StorageKeys";
import { storage } from "../utils/Storage";
import useGetAppointments from '../api/useGetAppointments';
import SelectedSupply from '../components/SelectedSupply';
import Page from '../components/Page';
import ErrorSnackbar from '../components/CustomSnackbar';
import Loading from '../components/Loading';
import usePagination from '../hooks/usePagination';
import CustomButton from '../components/CustomButton';
import { useNavigate } from "react-router-dom";
import { Routes } from "../constants/Routes";
 
const BookingLayout = () => {
  const { getAppointments, loading, error, data: availableAppointments } = useGetAppointments();
  const theme = useTheme();
  const [lastDate, setLastDate] = useState(new Date());
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  const selectedSupply = storage.get(StorageKeys.SELECTED_SUPPLY);
  const selectedAppointment = storage.get(StorageKeys.SELECTED_APPOINTMENT);

  const {
    currentPage,
    paginatedItems,
    handlePrevPage,
    handleNextPage
  } = usePagination(availableAppointments || [], () => fetchNextPages);

  const fetchNextPages = () => {
    const newDate = new Date(lastDate);
    newDate.setDate(newDate.getDate() + 12);
    const date = newDate.toLocaleDateString().replaceAll(". ", "-").replaceAll(".", "");
    getAppointments({ date: date });
    setLastDate(newDate);
  }

  useEffect(() => {
    getAppointments();
  }, []);

  useEffect(() => {
    if (error) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    } 
  }, [error]);


  const handleAppointmentClick = (date, time) => {
    storage.set(StorageKeys.SELECTED_APPOINTMENT, { date, time });
    setDrawerOpen(true);
  };

  const handleCancelAppointment = () => {
    storage.remove(StorageKeys.SELECTED_APPOINTMENT);
    setDrawerOpen(false);
  }


  const renderDays = () => {
    return paginatedItems.map(appointment => {
      const date = appointment.date;
      return (
        <Grid key={date} item xs={4} sm={3} md={3} lg={2}>
          <Card>
            <Stack direction={{ xs: "column", xl: "row" }} justifyContent="space-around" alignItems="center" p={1} bgcolor="black">
              <Typography variant="h6" textTransform="capitalize" color="white" noWrap>
                {appointment.weekday}
              </Typography>
              <Typography variant="body1" color="grey.200" noWrap fontSize={{ xs: 14, md: 16 }}>
                {appointment.formattedDate}
              </Typography>
            </Stack>
            <Box sx={{ height: "50vh", overflowY: "auto" }}>
              <Stack p={1} spacing={1}>
                {appointment.times.map(time => (
                  <Button
                    key={`${date}-${time}`}
                    variant={
                      selectedAppointment && selectedAppointment.date === date && selectedAppointment.time === time ?
                        "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => handleAppointmentClick(date, time)}
                  >
                    {time}
                  </Button>
                ))}
              </Stack>
            </Box>
          </Card>
        </Grid>
      );
    });
  };

  return (
    <>
      <ErrorSnackbar 
        open={snackbarOpen} 
        setOpen={setSnackbarOpen}
        message={snackbarMessage}/>

      <Drawer anchor="bottom" open={drawerOpen} onClose={handleCancelAppointment}>
        <Stack direction="row" spacing={3} justifyContent="space-around" alignItems="center" p={3}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box sx={{
              p: 2,
              borderRadius: "5px",
              border: `1px solid ${theme.palette.primary.main}`,
              width: "150px"
            }}>
              <Typography color={theme.palette.primary.main} fontWeight="bold" gutterBottom>
                {selectedSupply?.name}
              </Typography>
              <Typography variant="body1">
                {selectedSupply?.price} Ft
              </Typography>
              <Typography variant="body1">
                {selectedSupply?.duration} perc
              </Typography>
            </Box>

            {selectedAppointment &&
              <Stack>
                <Typography fontWeight="bold" noWrap>{selectedAppointment.date.replaceAll("-", ".")}</Typography>
                <Typography color="text.primary" fontSize={12}>{selectedAppointment.time}</Typography>
              </Stack>
            }
          </Stack>

          <CustomButton text="Tovább" onClick={() => navigate(Routes.PRE_RESERVE)}/>
        </Stack>
      </Drawer>
      
      <Page>
        <Paper elevation={3} sx={{ py: 2, px: 3 }}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Typography variant="h5" fontWeight="bold" color="black">A választott szolgáltatás</Typography>
            <SelectedSupply supply={selectedSupply} />
          </Stack>
        </Paper>

        {(loading ) && <Loading />}

        {!loading && !error && (
          <>
            <Box mt={5} mb={3} display="flex" justifyContent="center" alignItems="center">
            <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton onClick={handleNextPage}>
              <ArrowForwardIosIcon />
            </IconButton>
            </Box>
            <Grid container spacing={2}>
              {renderDays()}
            </Grid>
          </>
        )}
      </Page>
    </>
  );
};

export default BookingLayout;
