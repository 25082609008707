// src/router.js
import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import RootLayout from './layouts/RootLayout';
import HomeLayout from './layouts/HomeLayout';
import SupplyLayout from './layouts/SupplyLayout';
import ProfileLayout from './layouts/ProfileLayout';
import BookingLayout from './layouts/BookingLayout';
import ConfirmBookingLayout from './layouts/ConfirmBookingLayout';
import LoginLayout from './layouts/LoginLayout';
import SuccessfulAuthLayout from './layouts/redirect/SuccessfulAuthLayout';
import ErrorLayout from './layouts/redirect/ErrorLayout';
import CompleteRegistrationLayout from './layouts/redirect/CompleteRegistrationLayout';
import LogoutLayout from './layouts/LogoutLayout';
import { Routes } from "./constants/Routes";
import { ProtectedBookingRoute, ProtectedPrePreserveRoute, ProtectedConfirmBookingRoute, ProtectedProfileRoute } from './components/ProtectedRoutes';
import PreReserveLayout from './layouts/PreReserveLayout';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />} >

      {/* Home */}
      <Route index element={<HomeLayout />} />

      {/* Supply */}
      <Route path={Routes.SUPPLY} element={<SupplyLayout />} />

      {/* Booking */}
      <Route path={Routes.BOOKING} element={
        <ProtectedBookingRoute>
          <BookingLayout />
        </ProtectedBookingRoute> 
      } />

      {/* Login */}
      <Route path={Routes.LOGIN} element={<LoginLayout />} />

      {/* Pre reserve */}
      <Route path={Routes.PRE_RESERVE} element={
        <ProtectedPrePreserveRoute>
          <PreReserveLayout />
        </ProtectedPrePreserveRoute>
      } />

      {/* Confirm booking */}
      <Route path={Routes.CONFIRM_BOOKING} element={
        <ProtectedConfirmBookingRoute>
          <ConfirmBookingLayout />
        </ProtectedConfirmBookingRoute>
      } />

      {/* Profile */}
      <Route path={Routes.PROFILE} element={
        <ProtectedProfileRoute>
          <ProfileLayout />
        </ProtectedProfileRoute>
      } />

      {/* Logout */}
      <Route path={Routes.LOGOUT} element={<LogoutLayout />} />

      {/* Backend Redirects */}
      <Route path={Routes.COMPLETE_REGISTRATION} element={<CompleteRegistrationLayout />} />
      <Route path={Routes.SUCCESSFUL_AUTH} element={<SuccessfulAuthLayout />} />
      <Route path={Routes.ERROR} element={<ErrorLayout />} />
    </Route>
  )
);

export default router;
