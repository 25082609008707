import { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Typography,
  Paper,
  useTheme,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import useGetUser from "../api/useGetUser";
import SelectedSupply from "../components/SelectedSupply";
import { alpha } from "@mui/material/styles";
import useCancelBooking from '../api/useCancelBooking';
import Page from '../components/Page';
import CustomSnackbar from '../components/CustomSnackbar';

const ProfileLayout = () => {
  const { getUser, loading: getUserLoading, error: getUserError, data: getUserData } = useGetUser();
  const { cancelBooking, error: cancelBookingError, success: cancelBookingSuccess } = useCancelBooking();
  const theme = useTheme();
  const [bookingToBeCancelled, setBookingToBeCancelled] = useState(null);
  const [isDialogOpen, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState();

  useEffect(() => {
    getUser();
  }, [cancelBookingSuccess]);

  useEffect(() => {
    if (getUserError) {
      showSnackbar(getUserError?.message);
      return;
    }

    if (cancelBookingError) {
      showSnackbar(cancelBookingError?.message);
      return;
    }

    if (cancelBookingSuccess) {
      showSnackbar("Sikeresen lemondtad a foglalást", "success")
    }

  }, [getUserError, cancelBookingError, cancelBookingSuccess]);
  
  
  const showSnackbar = async (message, severity = "error") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpenDialog = (bookingId) => {
    setBookingToBeCancelled(bookingId);
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
    setBookingToBeCancelled(null);
  };

  const handleConfirmCancel = () => {
    if (bookingToBeCancelled) {
      handleCancelBooking(bookingToBeCancelled);
    }
    handleCloseDialog();
  };

  const handleCancelBooking = (bookingId) => {  
    console.log(`Cancel booking with ID: ${bookingId}`);
    cancelBooking({ bookingId: bookingId });
  };
 
  return (
    <>
      <CustomSnackbar 
        open={snackbarOpen} 
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}/>

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Foglalás törlése</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Biztosan törölni szeretnéd ezt a foglalást?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Mégse
          </Button>
          <Button variant="contained" onClick={handleConfirmCancel} color="error" fontWeight="bold">
            <Typography textTransform="none">Törlés</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      
      <Page>
        {getUserLoading && <Typography>Profil adatainak betöltése...</Typography>}

        {!getUserLoading && getUserData && (
          <Stack direction={{ sm: "column",  md: "row" }}>
            <Paper elevation={3} sx={{ p: 4, m: 2, width: { xs:"80vw", md: "40vw" }, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <Typography variant="h4" textAlign="center" fontWeight="bold" mb={4}>Foglalások</Typography>
              <Stack spacing={2} divider={<Divider orientation="horizontal" flexItem/>}>
                {getUserData.bookings.map((booking) => (
                  <Box key={booking?.id}>
                    <Stack direction="row" alignItems="center" spacing={2} divider={<Divider orientation="vertical" flexItem/>}>
                      <Box>
                        <Typography gutterBottom>{booking?.date.replaceAll("-", ".")}</Typography>
                        <Typography variant="body2" noWrap>{`${booking?.fromTime} - ${booking?.toTime}`}</Typography> 
                      </Box>
                      <SelectedSupply supply={booking?.supply} />
                      <IconButton
                        color="error"
                        onClick={() => handleOpenDialog(booking.id)}
                        sx={{
                          color: theme.palette.error.main,
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.error.light, 0.3),
                          },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </Box>
                ))}
              </Stack>
            </Paper>

            <Paper elevation={3} sx={{ p: 4, m: 2 }}>
              <Typography variant="h4" textAlign="center" fontWeight="bold" mb={4}>Profil</Typography>
              <Stack spacing={3}>
                <Box>
                  <Typography fontWeight="bold">Név</Typography>
                  <Box sx={{ 
                    p: 2, 
                    borderRadius: "5px", 
                    border: `1px solid ${theme.palette.primary.main}`,
                    minWidth: "150px" 
                  }}>
                    <Typography>{getUserData.name}</Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography fontWeight="bold">Email</Typography>
                  <Box sx={{ 
                    p: 2, 
                    borderRadius: "5px", 
                    border: `1px solid ${theme.palette.primary.main}`,
                    minWidth: "150px" 
                  }}>
                    <Typography>{getUserData.email}</Typography>
                  </Box>
                </Box>  

                <Box>
                  <Typography fontWeight="bold">Telefonszám</Typography>
                  <Box sx={{ 
                    p: 2, 
                    borderRadius: "5px", 
                    border: `1px solid ${theme.palette.primary.main}`,
                    minWidth: "150px" 
                  }}>
                    <Typography>{getUserData.phoneNumber}</Typography>
                  </Box>
                </Box>
              </Stack>
            </Paper>
          </Stack>
        )}
      </Page>
    </>
  );
  
};

export default ProfileLayout;
