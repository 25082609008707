import { useState } from 'react';
import apiClient, { handleError } from './ApiClient';
import { StorageKeys } from "../constants/StorageKeys";
import { storage } from "../utils/Storage";
import { useNavigate } from "react-router-dom";
import { Routes } from "../constants/Routes"

const preReserveEndpoint = '/v1/booking/pre-reserve';

const usePreReserve = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  const preReserve = async (requestData) => {
    console.log("preReserve api called", preReserveEndpoint, requestData);
    setLoading(true);
    setError(null);

    try {
      const response = await apiClient.post(preReserveEndpoint, requestData);
      validateResponse(response);
      return response.data.id;
    } catch (error) {
      if (error?.response?.status === 401) {
        //TODO make it common
        storage.set(StorageKeys.IS_USER_AUTHENTICATED, false)
        console.log("Request is unauthenticated, redirect needed to login route");
        storage.set(StorageKeys.TARGET_ROUTE_BEFORE_LOGIN, Routes.PRE_RESERVE);
        navigate(Routes.LOGIN);
      } else {
        handleError(error, setError);
      }      
    } finally {
      setLoading(false);
    }
  };

  return { preReserve, loading, error };
};

const validateResponse = (response) => {
  if (!response) {
    console.error("No response from preReserve");
    return;
  }

  if (!response.data) {
      console.error("Response data is missing:", response);
      return;
  }

  if (!response.data.id) {
      console.error("PreReserve response missing ID:", response.data);
      return;
  }
};

export default usePreReserve;
