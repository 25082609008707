import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { StorageKeys } from "../constants/StorageKeys";
import { storage } from "../utils/Storage";
import { Typography, Paper } from "@mui/material";
import Page from '../components/Page';

const LogoutLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    storage.set(StorageKeys.IS_USER_AUTHENTICATED, false);
    navigate("/")
  }, []);

  return (
    <Page>
      <Paper elevation={3} sx={{ py: 5, px: 4, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Kijelentkezés folyamatban...
          </Typography>
        </Paper>
    </Page>
  );
}

export default LogoutLayout
